import { AccountInfo, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";
import { action, computed, observable } from "mobx";
import { ConnectableServiceInfoDto, UserProfileDto } from "src/api";
import { RootStore } from "src/stores/RootStore";
import { UserRouteNames } from "src/pages/user/UserRoutes";
import { OrgRouteNames } from "src/pages/org/OrganizationRoutes";

export class UserProfileStore implements AccountSelectStore {
    @observable profile?: UserProfileDto;
    @observable orgArray: AccountInfo[] = [];
    @observable connectableServices: ConnectableServiceInfoDto[] = [];

    constructor(private readonly root: RootStore) {}

    @computed get supportedAccounts(): AccountInfo[] {
        const title = this.profile?.name || this.profile?.externalId;
        const accountReferences: AccountInfo[] = [
            {
                title: title ?? "Загрузка...",
                description: "Физическое лицо",
                route: {
                    name: UserRouteNames.organizations,
                },
            },
        ];
        return [...accountReferences, ...this.orgArray];
    }

    @action
    async selectAccount(account: AccountInfo): Promise<void> {
        await this.root.routerStore.goTo(account.route.name, account.route.params);
    }

    @action
    async loadProfile() {
        this.profile = await this.root.userRpc.userProfile.getProfile();
        const orgList = await this.root.userRpc.userOrganization.getAll(0, 3, "");
        this.connectableServices = await this.root.userRpc.userConnectableService.getServices();
        this.orgArray = orgList.items.map((el) => {
            return {
                title: el.title,
                description: "Юридическое лицо",
                route: {
                    name: OrgRouteNames.organization,
                    params: {
                        oid: String(el.id),
                    },
                },
            };
        });
    }

    @action
    async getDefaultStaffRoute(): Promise<string | undefined> {
        await this.loadProfile();
        const roles = this.supportedAccounts;
        return roles[0]?.route.name;
    }

    reload(): Promise<void> {
        return this.loadProfile();
    }
}
