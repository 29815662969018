import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./global.css";
import "./globalBS.css";
import { observer, Provider } from "mobx-react";
import { RootStore } from "src/stores/RootStore";
import { ProjectShell } from "src/components/Shells/ProjectShell";
import { AnonShell } from "src/components/Shells/AnonShell";
import { OrgShell } from "src/components/Shells/OrgShell";
import { UserShell } from "src/components/Shells/UserShell";
import { AdminShell } from "src/components/Shells/AdminShell";
import "mobx-react-lite/batchingForReactDom";

let root: RootStore;

const ensureInitialized = () => {
    if (root) return;
    root = new RootStore();
    root.historyAdapter.observeRouterStateChanges();
};

export const App = observer(() => {
    ensureInitialized();
    const route = root.routerStore.routerState;
    const isUserAccounting = route.routeName.startsWith("accounting") && route.params["oid"] === "user";
    const isOrgAccounting = route.routeName.startsWith("accounting") && route.params["oid"] !== "user";
    return (
        <Provider rootStore={root}>
            {route.routeName.startsWith("user") || isUserAccounting ? (
                <UserShell />
            ) : route.routeName.startsWith("org") || isOrgAccounting ? (
                <OrgShell />
            ) : route.routeName.startsWith("project") ? (
                <ProjectShell />
            ) : route.routeName.startsWith("admin") ? (
                <AdminShell />
            ) : (
                <AnonShell />
            )}
        </Provider>
    );
});
