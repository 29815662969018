import { useObserver } from "mobx-react";
import * as React from "react";
import { FC, useState } from "react";
import style from "./accountSelector.module.css";
import { StringMap } from "mobx-state-router/dist/types/router-store";

export const AccountSelect: FC<{ store: AccountSelectStore }> = ({ children, store }) => {
    const [headerOpen, setHeaderOpen] = useState(false);
    return useObserver(() => (
        <div
            className={style.accountSelectContainer}
            onClick={async () => {
                if (!headerOpen) {
                    await store.reload();
                }
                setHeaderOpen(!headerOpen);
            }}
        >
            {children}
            <div className={`${headerOpen ? style.openAccountSelector : ""} ${style.accountSelectorList}`}>
                {store.supportedAccounts?.map((el, index) => (
                    <div key={index} className={`${style.accountSelectorItem}`} onClick={() => store.selectAccount(el)}>
                        <div className={`${style.accountSelectorItemTitle}`}>{el.title}</div>
                        <div className={`${style.accountSelectorItemDescription}`}>{el.description}</div>
                    </div>
                ))}
            </div>
        </div>
    ));
};

export interface AccountSelectStore {
    supportedAccounts: AccountInfo[];

    selectAccount(account: AccountInfo): Promise<void>;

    reload(): Promise<void>;
}

type AccountInfoRoute = {
    name: string;
    params?: StringMap;
};

export interface AccountInfo {
    title: string;
    description: string;
    route: AccountInfoRoute;
}
