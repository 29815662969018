import { Route, RouterState } from "mobx-state-router";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import {
    ProjectAccountAddPage,
    ProjectAccountEditPage,
    ProjectAccountListPage,
    ProjectConnectPage,
    ProjectContractAddPage,
    ProjectContractEditPage,
    ProjectContractListPage,
    ProjectCurrencyAddPage,
    ProjectCurrencyEditPage,
    ProjectCurrencyListPage,
    ProjectEconomicEntityAddPage,
    ProjectEconomicEntityEditPage,
    ProjectEconomicEntityListPage,
    ProjectServiceAddPage,
    ProjectServiceEditPage,
    ProjectServiceListPage,
    ProjectServiceProvisionAddPage,
    ProjectServiceProvisionListPage,
    ProjectSubAccountAddPage,
    ProjectSubAccountEditPage,
    ProjectSubAccountListPage,
    ProjectTariffAddPage,
    ProjectTariffEditPage,
    ProjectTariffListPage,
    ProjectTariffVersionAddPage,
    ProjectTariffVersionEditPage,
    ProjectTransactionListPage,
    ProjectTransactionPage,
} from "src/pages/project/ProjectLazyRoutes";
import React from "react";
import { UserAuthorizedOnlyHook } from "src/pages/user/UserRoutes";

const ProjectEnsureLoadedHook: RouteTransitionHook = async (root, next, to) => {
    const loadedId = root.projectStore.args?.oid;
    const loadedProject = root.projectStore.profile;
    if (loadedId && loadedProject && loadedProject.id == loadedId) {
        return;
    } else {
        await root.projectStore.load({
            oid: to.params["oid"],
            pid: to.params["pid"],
        });
    }
};

const ProjectEnsureConnectedHook: RouteTransitionHook = async (root, next, to) => {
    if (!root.projectStore.isConnected) {
        throw new RouterState(
            ProjectRouteNames.connect,
            {
                oid: to.params["oid"],
                pid: to.params["pid"],
            },
            {
                route: JSON.stringify(to),
            }
        );
    }
};

export enum ProjectRouteNames {
    connect = "project-connect",
    economicEntityList = "project-economic-entity-list",
    economicEntityAdd = "project-economic-entity-add",
    economicEntityEdit = "project-economic-entity-edit",
    contractList = "project-contract-list",
    contractEdit = "project-contract",
    contractAdd = "project-contract-add",
    serviceProvisionList = "project-service-provision-list",
    serviceProvisionAdd = "project-service-provision-add",
    currencyList = "project-currency-list",
    currencyAdd = "project-currency-add",
    currencyEdit = "project-currency-edit",
    serviceList = "project-service-list",
    serviceAdd = "project-service-add",
    serviceEdit = "project-service-edit",
    tariffList = "project-tariff-list",
    tariffAdd = "project-tariff-add",
    tariffEdit = "project-tariff-edit",
    tariffVersionAdd = "project-tariff-version-add",
    tariffVersionEdit = "project-tariff-version-edit",
    accountList = "project-account-list",
    accountEdit = "project-account",
    accountAdd = "project-account-add",
    subAccountList = "project-sub-account-list",
    subAccount = "project-sub-account",
    subAccountAdd = "project-sub-account-add",
    transactionList = "project-transaction-list",
    transaction = "project-transaction",
}

export const ProjectViewMap = {
    [ProjectRouteNames.connect]: <ProjectConnectPage />,
    [ProjectRouteNames.economicEntityList]: <ProjectEconomicEntityListPage />,
    [ProjectRouteNames.economicEntityAdd]: <ProjectEconomicEntityAddPage />,
    [ProjectRouteNames.economicEntityEdit]: <ProjectEconomicEntityEditPage />,
    [ProjectRouteNames.contractList]: <ProjectContractListPage />,
    [ProjectRouteNames.contractAdd]: <ProjectContractAddPage />,
    [ProjectRouteNames.contractEdit]: <ProjectContractEditPage />,
    [ProjectRouteNames.serviceProvisionList]: <ProjectServiceProvisionListPage />,
    [ProjectRouteNames.serviceProvisionAdd]: <ProjectServiceProvisionAddPage />,
    [ProjectRouteNames.currencyList]: <ProjectCurrencyListPage />,
    [ProjectRouteNames.currencyAdd]: <ProjectCurrencyAddPage />,
    [ProjectRouteNames.currencyEdit]: <ProjectCurrencyEditPage />,
    [ProjectRouteNames.serviceList]: <ProjectServiceListPage />,
    [ProjectRouteNames.serviceAdd]: <ProjectServiceAddPage />,
    [ProjectRouteNames.serviceEdit]: <ProjectServiceEditPage />,
    [ProjectRouteNames.tariffList]: <ProjectTariffListPage />,
    [ProjectRouteNames.tariffAdd]: <ProjectTariffAddPage />,
    [ProjectRouteNames.tariffEdit]: <ProjectTariffEditPage />,
    [ProjectRouteNames.tariffVersionAdd]: <ProjectTariffVersionAddPage />,
    [ProjectRouteNames.tariffVersionEdit]: <ProjectTariffVersionEditPage />,
    [ProjectRouteNames.accountList]: <ProjectAccountListPage />,
    [ProjectRouteNames.accountEdit]: <ProjectAccountEditPage />,
    [ProjectRouteNames.accountAdd]: <ProjectAccountAddPage />,
    [ProjectRouteNames.subAccountList]: <ProjectSubAccountListPage />,
    [ProjectRouteNames.subAccount]: <ProjectSubAccountEditPage />,
    [ProjectRouteNames.subAccountAdd]: <ProjectSubAccountAddPage />,
    [ProjectRouteNames.transactionList]: <ProjectTransactionListPage />,
    [ProjectRouteNames.transaction]: <ProjectTransactionPage />,
};

const ProjectEconomicEntityRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/economic-entity",
        name: ProjectRouteNames.economicEntityList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectEconomicEntityStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/add",
        name: ProjectRouteNames.economicEntityAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectEconomicEntityStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId",
        name: ProjectRouteNames.economicEntityEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectEconomicEntityStore.loadWithEditor(to.params["economicEntityId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectContractRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/contract",
        name: ProjectRouteNames.contractList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectContractStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/contract/add",
        name: ProjectRouteNames.contractAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectContractStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/contract/:contractId",
        name: ProjectRouteNames.contractEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectContractStore.loadWithEditor(to.params["contractId"], {
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectServiceProvisionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/service-provision/",
        name: ProjectRouteNames.serviceProvisionList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: async (root, to) =>
            root.projectServiceProvisionStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/service-provision/add",
        name: ProjectRouteNames.serviceProvisionAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: async (root, to) =>
            root.projectServiceProvisionStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectCurrencyRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/currency",
        name: ProjectRouteNames.currencyList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectCurrencyStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/currency/add",
        name: ProjectRouteNames.currencyAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectCurrencyStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/currency/:currencyId",
        name: ProjectRouteNames.currencyEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectCurrencyStore.loadWithEditor(to.params["currencyId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectServiceRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/service",
        name: ProjectRouteNames.serviceList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectServiceStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/service/add",
        name: ProjectRouteNames.serviceAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectServiceStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/service/:serviceId",
        name: ProjectRouteNames.serviceEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectServiceStore.loadWithEditor(to.params["serviceId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectTariffRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/tariff",
        name: ProjectRouteNames.tariffList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectTariffStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/tariff/add",
        name: ProjectRouteNames.tariffAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectTariffStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/tariff/:tariffId",
        name: ProjectRouteNames.tariffEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectTariffStore.loadWithEditor(to.params["tariffId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectTariffVersionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/tariff/:tariffId/version/add",
        name: ProjectRouteNames.tariffVersionAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectTariffVersionStore.load({
                tariffId: to.params["tariffId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/tariff/:tariffId/version/:versionId",
        name: ProjectRouteNames.tariffVersionEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectTariffVersionStore.loadWithEditor(to.params["versionId"], {
                tariffId: to.params["tariffId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectAccountRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account",
        name: ProjectRouteNames.accountList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectAccountStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/add",
        name: ProjectRouteNames.accountAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectAccountStore.load({
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId",
        name: ProjectRouteNames.accountEdit,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectAccountStore.loadWithEditor(to.params["accountId"], {
                economicEntityId: to.params["economicEntityId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectSubAccountRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId/sub-account",
        name: ProjectRouteNames.subAccountList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectSubAccountStore.load({
                economicEntityId: to.params["economicEntityId"],
                accountId: to.params["accountId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId/sub-account/add",
        name: ProjectRouteNames.subAccountAdd,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: (root, to) =>
            root.projectSubAccountStore.load({
                economicEntityId: to.params["economicEntityId"],
                accountId: to.params["accountId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern:
            "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId/sub-account/:subAccountId",
        name: ProjectRouteNames.subAccount,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: async (root, to) =>
            root.projectSubAccountStore.loadWithEditor(to.params["subAccountId"], {
                economicEntityId: to.params["economicEntityId"],
                accountId: to.params["accountId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectTransactionRoutes: Route[] = convertRoutes([
    {
        pattern:
            "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId/sub-account/:subAccountId/transaction",
        name: ProjectRouteNames.transactionList,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: async (root, to) =>
            root.projectTransactionStore.load({
                economicEntityId: to.params["economicEntityId"],
                accountId: to.params["accountId"],
                subAccountId: to.params["subAccountId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern:
            "/organization/:oid/project/:pid/economic-entity/:economicEntityId/account/:accountId/sub-account/:subAccountId/transaction/:transactionId",
        name: ProjectRouteNames.transaction,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook, ProjectEnsureConnectedHook],
        onEnter: async (root, to) =>
            root.projectTransactionStore.loadWithEditor(to.params["transactionId"], {
                economicEntityId: to.params["economicEntityId"],
                accountId: to.params["accountId"],
                subAccountId: to.params["subAccountId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const ProjectConnectionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/connect",
        name: ProjectRouteNames.connect,
        hooks: [UserAuthorizedOnlyHook, ProjectEnsureLoadedHook],
        onEnter: async (root, to) => {
            if (root.projectStore.isConnected) {
                await root.projectStore.goToConnectedRoute();
            }
        },
    },
]);

export const ProjectRoutes: Route[] = [
    ...ProjectConnectionRoutes,
    ...ProjectEconomicEntityRoutes,
    ...ProjectContractRoutes,
    ...ProjectServiceProvisionRoutes,
    ...ProjectCurrencyRoutes,
    ...ProjectServiceRoutes,
    ...ProjectTariffRoutes,
    ...ProjectTariffVersionRoutes,
    ...ProjectAccountRoutes,
    ...ProjectSubAccountRoutes,
    ...ProjectTransactionRoutes,
];
