import { RootStore } from "src/stores/RootStore";
import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    Result,
    UPanelAccountDto,
    UPanelEconomicEntityDto,
    UPanelInternalTransactionEntryDto,
    UPanelSubAccountDto,
    UPanelTransactionType,
} from "src/api";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { action, observable } from "mobx";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

type TransactionTypeInfo = {
    id: UPanelTransactionType;
    name: string;
};

export class TransactionTypeSelectStore extends ConfigurableAutoCompleteStore<TransactionTypeInfo> {
    @observable transactionTypeOptions: TransactionTypeInfo[] = [
        { id: UPanelTransactionType.PayIn, name: "Входящий" },
        { id: UPanelTransactionType.PayOut, name: "Исходящий" },
        { id: UPanelTransactionType.Transfer, name: "Перевод" },
    ];

    constructor(refresh: () => Promise<void>) {
        super({
            onSelectionChange: refresh,
            suggest: async (query) => {
                const filtered = this.transactionTypeOptions.filter((item) => item.name.includes(query));
                return { totalCount: filtered.length, items: filtered };
            },
        });
    }

    findName(type: UPanelTransactionType | undefined): string {
        return this.transactionTypeOptions.find((i) => i.id == type)?.name || "";
    }
}

type InternalTransactionCreateArgsDto = {};

type ProjectTransactionStoreErrors = {};

type ProjectTransactionStoreArgs = {
    economicEntityId: string;
    accountId: string;
    subAccountId: string;
    oid: string;
    pid: string;
};

export class ProjectTransactionStore extends CrudEditorStore<
    UPanelInternalTransactionEntryDto,
    InternalTransactionCreateArgsDto,
    ProjectTransactionStoreErrors,
    ProjectTransactionStoreArgs
> {
    @observable
    subAccount?: UPanelSubAccountDto;
    @observable
    item?: UPanelInternalTransactionEntryDto;
    @observable
    transactionTypes: TransactionTypeSelectStore = new TransactionTypeSelectStore(() => this.refresh());
    @observable
    economicEntity?: UPanelEconomicEntityDto;
    @observable
    account?: UPanelAccountDto;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.transactionList, ProjectRouteNames.transaction);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const economicEntity = await this.root.userRpc.projectEconomicEntity.getById(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId
        );
        const account = await this.root.userRpc.projectAccount.getById(
            this.args.oid,
            this.args.pid,
            this.args.accountId
        );
        const subAccount = await this.root.userRpc.projectSubAccount.getById(
            this.args.oid,
            this.args.pid,
            this.args.subAccountId
        );
        this.account = account.value;
        this.subAccount = subAccount.value;
        this.economicEntity = economicEntity.value;
    }

    protected getAll(): Promise<ListResult<UPanelInternalTransactionEntryDto>> {
        return this.root.userRpc.projectInternalTransactionEntry.getAll(
            this.args.oid,
            this.args.pid,
            this.args.subAccountId,
            this.transactionTypes.value?.id || null,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelInternalTransactionEntryDto | undefined> {
        const response = await this.root.userRpc.projectInternalTransactionEntry.getById(
            this.args.oid,
            this.args.pid,
            id
        );
        return response.success ? response.value : undefined;
    }

    protected update(args: InternalTransactionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected createNew(args: InternalTransactionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected getFields(): InternalTransactionCreateArgsDto {
        return {};
    }

    protected resetFields(): void {
        this.item = undefined;
    }

    protected setFields(item: UPanelInternalTransactionEntryDto): void {
        this.item = item;
    }
}
