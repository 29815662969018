import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { ListResult, RemoteUiDefinitionDto, Result } from "src/api";
import { StringMap } from "mobx-state-router/dist/types/router-store";
import { ReactTableStore } from "./ReactTableStore";
import { RemoteUiEditorStore } from "@kekekeks/remoteui/src";
import { validate } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";

export class ConfirmableAction<TArgs = string> {
    @observable showSuccessBox = false;
    @observable showConfirmationBox = false;
    private args?: TArgs;

    constructor(private readonly action: (args: TArgs) => Promise<boolean>) {}

    @action confirm = (args: TArgs) => {
        if (this.showConfirmationBox) return;
        this.showConfirmationBox = true;
        this.showSuccessBox = false;
        this.args = args;
    };

    @action execute = async () => {
        if (!this.showConfirmationBox || !this.args) return;
        let success = false;
        try {
            success = await this.action(this.args);
        } finally {
            this.showConfirmationBox = false;
            this.showSuccessBox = success;
            this.args = undefined;
        }
    };
}

export abstract class CrudEditorStore<
    T extends { id: string },
    TStoreArgs,
    TStoreErrors,
    TRouteArguments extends {}
> extends ReactTableStore<T, TRouteArguments> {
    private readonly validation: () => Promise<TStoreArgs | undefined>;
    readonly dummySelectedItemId: string = "dummy";

    @observable selectedItemId: string = this.dummySelectedItemId;
    @observable showSuccessBox: boolean = false;
    @observable errors?: TStoreErrors;
    @observable error?: string;
    @observable deleteAction: ConfirmableAction;

    protected constructor(
        protected readonly root: RootStore,
        private readonly returnRoute: string,
        private readonly addItemRoute: string,
        validation?: () => Promise<TStoreArgs | undefined>
    ) {
        super();
        this.showPageSizeOptions = true;
        this.deleteAction = new ConfirmableAction(this.confirmSelectedItemRemoval);
        this.validation =
            validation ??
            (async () => {
                const errors = await validate(this);
                if (errors.length != 0) {
                    this.errors = reduceValidationErrorsToErrors<TStoreErrors>(errors);
                    return undefined;
                } else {
                    return this.getFields();
                }
            });
    }

    protected abstract getAll(): Promise<ListResult<T>>;
    protected abstract getById(id: string): Promise<T | undefined>;
    protected abstract update(args: TStoreArgs): Promise<Result>;
    protected abstract createNew(args: TStoreArgs): Promise<Result>;
    protected abstract deleteById(id: string): Promise<Result>;

    protected abstract getFields(): TStoreArgs | Promise<TStoreArgs>;
    protected abstract resetFields(): void | Promise<void>;
    protected abstract setFields(item: T): void | Promise<void>;
    protected async onLoaded(): Promise<void> {}

    @action unload() {
        if (this.search) {
            this.page = 0;
            this.search = "";
        }
    }

    @action async refresh() {
        this.error = undefined;
        this.errors = undefined;
        this.showSuccessBox = false;
        this.selectedItemId = this.dummySelectedItemId; // Fuck off Router
        const { items, totalCount } = await this.getAll();
        this.fillItems(items, totalCount);
        await this.resetFields();
        await this.onLoaded();
    }

    @action async loadWithEditor(id: string, args?: TRouteArguments): Promise<void> {
        if (args) this.args = args;
        this.error = undefined;
        this.errors = undefined;
        this.showSuccessBox = false;
        this.selectedItemId = id;
        const item = await this.getById(id);
        if (item) {
            await this.setFields(item);
            await this.onLoaded();
        } else {
            await this.root.routerStore.goTo(this.returnRoute);
        }
    }

    @action async save(): Promise<void> {
        const updateArgs = await this.validation();
        if (!updateArgs) return;
        const response = await this.update(updateArgs);
        if (response.success) {
            this.resetFields();
            await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
            this.showSuccessBox = true;
        } else {
            this.error = response.error.description;
        }
    }

    @action async add(): Promise<void> {
        const createArgs = await this.validation();
        if (!createArgs) return;
        const response = await this.createNew(createArgs);
        if (response.success) {
            this.resetFields();
            await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
            await this.load();
            this.showSuccessBox = true;
        } else {
            this.error = response.error.description;
        }
    }

    @action removeSelectedItem = () => {
        if (this.selectedItemId) {
            this.deleteAction.confirm(this.selectedItemId);
        }
    };

    @action private confirmSelectedItemRemoval = async (selectedItemId: string) => {
        try {
            const response = await this.deleteById(selectedItemId);
            if (response.success) {
                this.resetFields();
                await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
                await this.load();
            } else {
                this.error = response.error?.description || "Ошибка при удалении элемента.";
            }
        } catch (e) {
            const exception = (e as Error).toString();
            if (exception.length > 500) {
                this.error = exception.substring(0, 500);
            } else {
                this.error = exception;
            }
        }

        return !this.error;
    };

    @action async goToAdd(): Promise<void> {
        await this.root.routerStore.goTo(this.addItemRoute, this.getRouteArgs());
    }

    @action async goToItems(): Promise<void> {
        await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
    }

    private getRouteArgs(): StringMap {
        return this.args ?? {};
    }
}

export abstract class RemoteUiCrudEditorStore<
    T extends { id: string },
    TCreateArgs,
    TRouteArguments extends {}
> extends CrudEditorStore<T, TCreateArgs, {}, TRouteArguments> {
    @observable editor?: RemoteUiEditorStore;

    protected constructor(root: RootStore, returnRoute: string, addItemRoute: string) {
        super(root, returnRoute, addItemRoute, async () => {
            return await this.getFields();
        });
    }

    protected abstract getAll(): Promise<ListResult<T>>;
    protected abstract getById(id: string): Promise<T | undefined>;
    protected abstract getRemoteUi(): Promise<RemoteUiDefinitionDto<TCreateArgs>>;
    protected abstract createNew(args: TCreateArgs): Promise<Result>;
    protected abstract update(args: TCreateArgs): Promise<Result>;
    protected abstract deleteById(id: string): Promise<Result>;
    protected async onLoaded(): Promise<void> {}

    @action
    protected async getFields(): Promise<TCreateArgs> {
        const data = await this.editor?.getDataAsync();
        return (data as any) as TCreateArgs;
    }

    @action
    protected async resetFields(): Promise<void> {
        const definition = await this.getRemoteUi();
        this.editor = new RemoteUiEditorStore(definition.definition, definition.value);
    }

    @action
    protected async setFields(item: T): Promise<void> {
        const definition = await this.getRemoteUi();
        this.editor = new RemoteUiEditorStore(definition.definition, item);
    }
}
