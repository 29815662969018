import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityDto,
    UPanelEconomicEntityServiceDto,
    UPanelEconomicEntityServiceProvisionCreateArgsDto,
    UPanelEconomicEntityServiceProvisionDto,
    ListResult,
    Result,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { action, computed, observable } from "mobx";
import { AutoCompleteBoxNotEmpty } from "src/utilities";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

type ProjectServiceProvisionRouteArgs = {
    economicEntityId: string;
    oid: string;
    pid: string;
};

type ProjectServiceProvisionStoreErrors = {
    contracts: string[];
    services: string[];
    name: string[];
};

export class ProjectServiceProvisionStore extends CrudEditorStore<
    UPanelEconomicEntityServiceProvisionDto,
    UPanelEconomicEntityServiceProvisionCreateArgsDto,
    ProjectServiceProvisionStoreErrors,
    ProjectServiceProvisionRouteArgs
> {
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    contracts: ConfigurableAutoCompleteStore<UPanelEconomicEntityContractDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    services: ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>;
    @observable
    name = "";

    @observable
    economicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.serviceProvisionList, ProjectRouteNames.serviceProvisionAdd);
        this.contracts = new ConfigurableAutoCompleteStore<UPanelEconomicEntityContractDto>({
            suggest: () =>
                this.root.userRpc.projectContract.getAll(
                    this.args.oid,
                    this.args.pid,
                    this.args.economicEntityId,
                    0,
                    30
                ),
            onSelectionChange: async () => {
                this.services.value = undefined;
                await this.services.suggest("");
            },
        });
        this.services = new ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>({
            suggest: async () =>
                this.contracts.value?.id
                    ? await this.root.userRpc.projectService.getAllByContract(
                          this.args.oid,
                          this.args.pid,
                          this.contracts.value?.id,
                          0,
                          30
                      )
                    : { items: [], totalCount: 0 },
        });
    }

    @computed
    get description() {
        return this.services.value?.name && this.contracts.value?.name
            ? `Разовое предоставление услуги '${this.services.value?.name}' по контракту '${this.contracts.value?.name}'`
            : "";
    }

    @action
    protected async onLoaded(): Promise<void> {
        const contract = await this.root.userRpc.projectEconomicEntity.getById(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId
        );
        this.economicEntity = contract.value;
    }

    protected createNew(args: UPanelEconomicEntityServiceProvisionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectServiceProvision.create(this.args.oid, this.args.pid, args);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>> {
        return this.root.userRpc.projectServiceProvision.getAll(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityServiceProvisionDto | undefined> {
        const response = await this.root.userRpc.projectServiceProvision.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected getFields(): UPanelEconomicEntityServiceProvisionCreateArgsDto {
        return {
            contractId: this.contracts.value?.id!,
            serviceId: this.services.value?.id!,
            name: this.name || this.description,
        };
    }

    protected resetFields(): void {
        this.contracts.value = undefined;
        this.services.value = undefined;
        this.name = "";
    }

    protected update(args: UPanelEconomicEntityServiceProvisionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected async setFields(item: UPanelEconomicEntityServiceProvisionDto) {
        const contract = await this.root.userRpc.projectContract.getById(this.args.oid, this.args.pid, item.contractId);
        const service = await this.root.userRpc.projectService.getById(this.args.oid, this.args.pid, item.serviceId);
        this.name = item.name;
        this.contracts.value = contract.value;
        this.services.value = service.value;
    }
}
