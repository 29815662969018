import * as React from "react";
import { FC } from "react";
import { RouterLink, RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { ProjectRouteNames, ProjectViewMap } from "src/pages/project/ProjectRoutes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "src/components/NavigationSidebar/NavigationSidebar";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import style from "../../styles/page.module.css";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import iconPayment from "src/assets/icon/payment.svg";
import iconShop from "src/assets/icon/shop.svg";
import iconChart from "src/assets/icon/chart.svg";
import iconListAlt from "src/assets/icon/list_alt.svg";
import iconMoney from "src/assets/icon/money.svg";
import { Logo } from "src/components/UI/Logo/Logo";
import { ProjectStore } from "src/stores/project/ProjectStore";
import { OrganizationStore } from "src/stores/org/OrganizationStore";
import { AccountSelect } from "src/components/AccountSelector/AccountSelector";

const ClientNavigation: FC<{
    title: string;
    description: string;
    onLogout: () => void;
    store: ProjectStore;
    organizationStore: OrganizationStore;
}> = ({ title, description, onLogout, store, organizationStore }) =>
    useObserver(() => {
        if (!store.profile) return <div>"Загрузка..."</div>;
        return (
            <NavigationSidebar>
                <AccountSelect store={store}>
                    <Logo color={"dark"} className={"mt-4"} title={title} description={description} />
                </AccountSelect>
                <LocalizedNavigationDelimiter content="Экономический субъект" />
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={ProjectRouteNames.economicEntityList}
                    params={{
                        pid: store.pid,
                        oid: organizationStore.oid,
                    }}
                >
                    <img src={iconListAlt} alt="" className="mr-3" />
                    Контрагенты
                </RouterLink>
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={ProjectRouteNames.accountList}
                    params={{
                        pid: store.pid,
                        oid: organizationStore.oid,
                        economicEntityId: store.profile.rootEconomicEntityId,
                    }}
                >
                    <img src={iconPayment} alt="" className="mr-3" />
                    Счета
                </RouterLink>
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={ProjectRouteNames.serviceList}
                    params={{
                        pid: store.pid,
                        oid: organizationStore.oid,
                    }}
                >
                    <img src={iconShop} alt="" className="mr-3" />
                    Услуги
                </RouterLink>
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={ProjectRouteNames.tariffList}
                    params={{
                        pid: store.pid,
                        oid: organizationStore.oid,
                    }}
                >
                    <img src={iconChart} alt="" className="mr-3" />
                    Тарифы
                </RouterLink>
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={ProjectRouteNames.currencyList}
                    params={{
                        pid: store.pid,
                        oid: organizationStore.oid,
                    }}
                >
                    <img src={iconMoney} alt="" className="mr-3" />
                    Валюты
                </RouterLink>
                <LocalizedNavigationDelimiter content="Кабинет клиента" />
                <a href="#" onClick={onLogout}>
                    <img src={iconLogOut} alt="" className="mr-3" />
                    Выйти
                </a>
            </NavigationSidebar>
        );
    });

export const ProjectShell: FC = () => {
    const {
        rootStore: { routerStore, projectStore, organizationStore },
    } = useRootStore();
    return useObserver(() => (
        <div className={style.fullHeight}>
            <div className={style.fullHeight + " container ml-0 " + style.fullWidth}>
                <div className={style.flexList + " row " + style.fullHeight}>
                    <div className={"col-lg-2 " + style.darkestBackground}>
                        <div className={"sticky-top mt-4"}>
                            <ClientNavigation
                                store={projectStore}
                                organizationStore={organizationStore}
                                title={projectStore.profile?.projectName ?? "Проект"}
                                description={projectStore.profile?.projectDescription ?? ""}
                                onLogout={() => projectStore.logout()}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-10 p-0 m-0"}>
                        <SuspensePlaceholder>
                            <RouterView routerStore={routerStore} viewMap={ProjectViewMap} />
                        </SuspensePlaceholder>
                    </div>
                </div>
            </div>
        </div>
    ));
};
