import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    AdminOrganizationCreateArgsDto,
    AdminOrganizationDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";
import { action, observable } from "mobx";

type AdminOrganizationStoreArgs = {};

export class AdminOrganizationStore extends RemoteUiCrudEditorStore<
    AdminOrganizationDto,
    AdminOrganizationCreateArgsDto,
    AdminOrganizationStoreArgs
> {
    @observable
    organization?: AdminOrganizationDto;

    constructor(root: RootStore) {
        super(root, AdminRouteNames.organizationList, AdminRouteNames.organizationList);
    }

    protected createNew(args: AdminOrganizationCreateArgsDto): Promise<Result> {
        throw new Error("Not implemented.");
        // return this.root.userRpc.adminOrganization.create(args);
    }

    protected getAll(): Promise<ListResult<AdminOrganizationDto>> {
        return this.root.userRpc.adminOrganization.getAll({
            userId: null,
            skip: this.skip,
            take: this.take,
            query: "",
            search: {
                keyword: "",
                property: ""
            }
        });
    }

    protected async getById(id: string): Promise<AdminOrganizationDto | undefined> {
        throw new Error("Not implemented.");
        // const response = await this.root.userRpc.adminOrganization.getById(id);
        // return response.success ? response.value : undefined;
    }

    protected update(args: AdminOrganizationCreateArgsDto): Promise<Result> {
        throw new Error("Not implemented.");
        // return this.root.userRpc.adminOrganization.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Not implemented.");
        // return this.root.userRpc.adminOrganization.delete(id);
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<AdminOrganizationCreateArgsDto>> {
        return await this.root.userRpc.adminOrganization.getRemoteUi();
    }

    @action
    protected async setFields(item: AdminOrganizationDto): Promise<void> {
        await super.setFields(item);
        this.organization = item;
    }
}
