import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    UPanelEconomicEntityCurrencyTypeDto,
    UPanelEconomicEntityRateProvisionDto,
    UPanelEconomicEntityServiceDto,
    UPanelEconomicEntityTariffVersionCreateArgsDto,
    UPanelEconomicEntityTariffVersionDto,
    ListResult,
    UPanelProvisionType,
    Result,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { ReactTableStore } from "src/stores/common/ReactTableStore";
import { action, observable } from "mobx";
import { validate, ValidateIf } from "@keroosha/class-validator";
import { AutoCompleteBoxNotEmpty, reduceValidationErrorsToErrors } from "src/utilities";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

type ProvisionTypeInfo = {
    id: UPanelProvisionType;
    name: string;
};

type ProjectTariffServiceStoreErrors = {
    serviceId?: string[];
    amount?: string[];
    currencyId?: string[];
    validUntil?: string[];
    provisionType?: string[];
    schedule?: string[];
    timestamp?: string[];
};

export class ProjectTariffServiceStore {
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    service: ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    currency: ConfigurableAutoCompleteStore<UPanelEconomicEntityCurrencyTypeDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    provisionType: ConfigurableAutoCompleteStore<ProvisionTypeInfo>;

    @ValidateIf((x: ProjectTariffServiceStore) => x.provisionType.value?.id === UPanelProvisionType.Continuously, {
        message: "Поле не может быть пустым.",
    })
    @observable
    schedule = "";

    @ValidateIf((x: ProjectTariffServiceStore) => x.provisionType.value?.id === UPanelProvisionType.Transaction, {
        message: "Поле не может быть пустым.",
    })
    @observable
    timestamp: string | null = null;

    @observable amount: string = "";
    @observable errors: ProjectTariffServiceStoreErrors = {};
    @observable isBusy: boolean = false;

    constructor(private readonly root: RootStore, private readonly versions: ProjectTariffVersionStore) {
        this.service = new ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>({
            suggest: () =>
                this.root.userRpc.projectService.getAll(this.versions.args.oid, this.versions.args.pid, 0, 30),
        });
        this.currency = new ConfigurableAutoCompleteStore<UPanelEconomicEntityCurrencyTypeDto>({
            suggest: () =>
                this.root.userRpc.projectCurrencyType.getAll(this.versions.args.oid, this.versions.args.pid, 0, 30),
        });
        const types = [
            { id: UPanelProvisionType.Continuously, name: "Непрерывный" },
            { id: UPanelProvisionType.Transaction, name: "Транзакционный" },
        ];
        this.provisionType = new ConfigurableAutoCompleteStore<ProvisionTypeInfo>({
            suggest: async (query) => ({ totalCount: 0, items: types.filter((item) => item.name.includes(query)) }),
        });
    }

    @action
    async attachService() {
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors<ProjectTariffServiceStoreErrors>(errors);
            return;
        }

        this.isBusy = true;
        const store = this.root.projectTariffVersionStore;
        try {
            const provision = this.provisionType.value!.id;
            const response = await this.root.userRpc.projectTariffVersion.attachService(
                this.versions.args.oid,
                this.versions.args.pid,
                store.selectedItemId,
                {
                    amount: parseFloat(this.amount),
                    provision: provision,
                    serviceId: this.service.value!.id,
                    currencyId: this.currency.value!.id,
                    schedule: provision === UPanelProvisionType.Continuously ? this.schedule : "",
                    timestamp: provision === UPanelProvisionType.Transaction ? this.timestamp : null,
                }
            );
            if (response.success) {
                await store.rates.refresh();
            } else {
                alert(response.error.description);
            }
        } finally {
            this.isBusy = false;
        }
    }
}

type ProjectTariffRateRouteArgs = {
    oid: string;
    pid: string;
};

export class ProjectTariffRateStore extends ReactTableStore<
    UPanelEconomicEntityRateProvisionDto,
    ProjectTariffRateRouteArgs
> {
    constructor(private readonly root: RootStore, readonly versions: ProjectTariffVersionStore) {
        super();
    }

    @action
    async refresh(): Promise<void> {
        const response = await this.root.userRpc.projectTariffVersion.getRates(
            this.args.oid,
            this.args.pid,
            this.versions.selectedItemId,
            this.skip,
            this.take
        );
        this.fillItems(response.items, response.totalCount);
    }
}

type ProjectTariffVersionRouteArgs = {
    tariffId: string;
    oid: string;
    pid: string;
};

type ProjectTariffVersionStoreErrors = {
    validSince: string[];
    validUntil: string[];
};

export class ProjectTariffVersionStore extends CrudEditorStore<
    UPanelEconomicEntityTariffVersionDto,
    UPanelEconomicEntityTariffVersionCreateArgsDto,
    ProjectTariffVersionStoreErrors,
    ProjectTariffVersionRouteArgs
> {
    @observable services: ProjectTariffServiceStore;
    @observable rates: ProjectTariffRateStore;

    @observable validSince: string | null = null;
    @observable validUntil: string | null = null;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.tariffEdit, ProjectRouteNames.tariffVersionAdd);
        this.rates = new ProjectTariffRateStore(root, this);
        this.services = new ProjectTariffServiceStore(root, this);
    }

    protected async onLoaded(): Promise<void> {
        if (this.selectedItemId === this.dummySelectedItemId) return;
        await this.rates.load({
            oid: this.args.oid,
            pid: this.args.pid,
        });
    }

    protected createNew(args: UPanelEconomicEntityTariffVersionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectTariffVersion.create(this.args.oid, this.args.pid, args);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityTariffVersionDto>> {
        return this.root.userRpc.projectTariffVersion.getAll(
            this.args.oid,
            this.args.pid,
            this.args.tariffId,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityTariffVersionDto | undefined> {
        const response = await this.root.userRpc.projectTariffVersion.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelEconomicEntityTariffVersionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectTariffVersion.update(this.args.oid, this.args.pid, this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.projectTariffVersion.delete(this.args.oid, this.args.pid, id);
    }

    protected getFields(): UPanelEconomicEntityTariffVersionCreateArgsDto {
        return {
            tariffId: this.args.tariffId,
            validSince: this.validSince,
            validUntil: this.validUntil,
        };
    }

    protected resetFields() {
        this.validSince = null;
        this.validUntil = null;
    }

    protected setFields(item: UPanelEconomicEntityTariffVersionDto) {
        this.validSince = item.validSince;
        this.validUntil = item.validUntil;
    }
}
