import { lazy } from "react";

export const AdminOrganizationListPage = lazy(
    () => import("src/pages/admin/AdminOrganization/AdminOrganizationListPage")
);
export const AdminOrganizationEditPage = lazy(
    () => import("src/pages/admin/AdminOrganization/AdminOrganizationEditPage")
);
export const AdminUserListPage = lazy(() => import("src/pages/admin/AdminUser/AdminUserListPage"));
export const AdminUserEditPage = lazy(() => import("src/pages/admin/AdminUser/AdminUserEditPage"));
