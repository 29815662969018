import { RootStore } from "src/stores/RootStore";
import { action } from "mobx";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";
import { UserRouteNames } from "src/pages/user/UserRoutes";

export class AdminLoginStore {
    constructor(private readonly root: RootStore) {}

    @action async login() {
        if (this.root.userRpc.isAuthorized()) {
            await this.root.routerStore.goTo(AdminRouteNames.organizationList);
        } else {
            await this.root.routerStore.goTo(UserRouteNames.organizations);
        }
    }

    @action async logout() {
        await this.root.routerStore.goTo(UserRouteNames.organizations);
        window.location.reload();
    }
}
