import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { UPanelProjectProfileDto } from "src/api";
import { RouterState } from "mobx-state-router";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { OrgRouteNames } from "src/pages/org/OrganizationRoutes";
import { AccountInfo, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";

type ProjectRouteArgs = {
    oid: string;
    pid: string;
};

export class ProjectStore implements AccountSelectStore {
    @observable profile?: UPanelProjectProfileDto;
    @observable args?: ProjectRouteArgs;
    @observable connected: boolean = false;
    @observable connecting: boolean = false;
    @observable projectList: AccountInfo[] = [];

    constructor(private readonly root: RootStore) {}

    @computed get pid() {
        return this.profile?.id ?? "ух бля";
    }

    @computed get isConnected() {
        return this.connected;
    }

    @computed get supportedAccounts(): AccountInfo[] {
        const organization: AccountInfo[] = [
            {
                title: this.profile?.organizationTitle ?? "Загрузка...",
                description: "Юридическое лицо",
                route: {
                    name: OrgRouteNames.organization,
                    params: {
                        oid: String(this.profile?.organizationId),
                    },
                },
            },
        ];
        return [...organization, ...this.projectList];
    }

    @action
    async load(args: ProjectRouteArgs) {
        this.args = args;
        this.profile = await this.root.userRpc.projectProfile.getProfile(args.oid, args.pid);
        const projects = await this.root.userRpc.organizationProject.getAll(args.oid, 0, 10);
        this.projectList = projects.items.map((el) => {
            return {
                title: el.name,
                description: "Проект организации",
                route: {
                    name: ProjectRouteNames.economicEntityList,
                    params: {
                        oid: String(args.oid),
                        pid: String(el.id),
                    },
                },
            };
        });
        await this.root.organizationStore.load({ oid: args.oid });
    }

    @action
    async connect() {
        if (this.connecting || this.connected) {
            return;
        }

        try {
            this.connecting = true;
            const oid = this.args!.oid;
            const pid = this.args!.pid;
            const response = await this.root.userRpc.projectProfile.heartbeat(oid, pid);
            this.connected = response.success;
        } catch (e) {
            this.connected = false;
        } finally {
            this.connecting = false;
        }

        if (this.connected) {
            await this.goToConnectedRoute();
        }
    }

    @action disconnect = async () => {
        this.connected = false;
        this.connecting = false;
    };

    @action
    async goToConnectedRoute(): Promise<void> {
        const defaultRoute = new RouterState(ProjectRouteNames.economicEntityList, {
            oid: this.args!.oid,
            pid: this.args!.pid,
        });
        const route = this.root.routerStore.routerState.queryParams["route"];
        const specifiedRoute = JSON.parse(route) as RouterState;
        const state = route ? specifiedRoute : defaultRoute;
        await this.root.routerStore.goTo(state.routeName, state.params, state.queryParams);
    }

    @action
    async logout(): Promise<void> {
        if (!this.args?.oid) return;
        await this.root.routerStore.goTo(OrgRouteNames.projectList, { oid: this.args.oid });
    }

    @action
    async reload(): Promise<void> {
        if (this.args) return this.load(this.args);
    }

    @action
    async selectAccount(account: AccountInfo): Promise<void> {
        await this.root.routerStore.goTo(account.route.name, account.route.params);
    }
}
