import * as React from "react";
import { RouterLink, RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { AdminRouteNames, AdminViewMap } from "src/pages/admin/AdminRoutes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { FC } from "react";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "src/components/NavigationSidebar/NavigationSidebar";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import style from "../../styles/page.module.css";
import iconGroup from "src/assets/icon/groups.svg";
import iconPerson from "src/assets/icon/person.svg";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import iconGroupAdd from "src/assets/icon/group_add.svg";
import { Logo } from "src/components/UI/Logo/Logo";
import { AccountSelect, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";

const AdminNavigation: FC<{
    title: string;
    description: string;
    onLogout: () => void;
    accountSelectStore: AccountSelectStore;
}> = ({ title, description, onLogout, accountSelectStore }) => (
    <NavigationSidebar>
        <AccountSelect store={accountSelectStore}>
            <Logo color={"dark"} className={"mt-4"} title={title} description={description} />
        </AccountSelect>
        <LocalizedNavigationDelimiter content="Управление" />
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.organizationList}>
            <img src={iconGroup} alt="" className="mr-3" />
            Организации
        </RouterLink>
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.userList}>
            <img src={iconPerson} alt="" className="mr-3" />
            Пользователи
        </RouterLink>
        <LocalizedNavigationDelimiter content="Кабинет администратора" />
        <a href="#" onClick={onLogout}>
            <img src={iconLogOut} alt="" className="mr-3" />
            Выйти
        </a>
    </NavigationSidebar>
);

export const AdminShell: FC = () => {
    const {
        rootStore: { routerStore, adminLoginStore, userProfileStore },
    } = useRootStore();
    return useObserver(() => (
        <div className={style.fullHeight}>
            <div className={style.fullHeight + " container ml-0 " + style.fullWidth}>
                <div className={style.flexList + " row " + style.fullHeight}>
                    <div className={"col-lg-2 " + style.adminBackground}>
                        <div className={"sticky-top mt-4"}>
                            <AdminNavigation
                                accountSelectStore={userProfileStore}
                                title={userProfileStore.profile?.name ?? "Безымянный"}
                                description={"Администратор"}
                                onLogout={() => adminLoginStore.logout()}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-10 p-0 m-0"}>
                        <SuspensePlaceholder>
                            <RouterView routerStore={routerStore} viewMap={AdminViewMap} />
                        </SuspensePlaceholder>
                    </div>
                </div>
            </div>
        </div>
    ));
};
