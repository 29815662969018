import { RootStore } from "src/stores/RootStore";
import { action } from "mobx";

export class UserLoginStore {
    constructor(private readonly root: RootStore) {}

    @action async logout(): Promise<void> {
        this.root.userRpc.resetStore();
        const logoutUrl = await this.root.userRpc.userLogin.startLogout();
        window.location.replace(logoutUrl);
    }

    @action async login(): Promise<void> {
        const startUrl = await this.root.userRpc.userLogin.startLogin();
        window.location.replace(startUrl);
    }
}
