import { RootStore } from "src/stores/RootStore";
import { ListResult, MessageBalloonDto } from "src/api";
import { SupportStore } from "src/components/SupportView/SupportStore";

export class UserSupportStore extends SupportStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    async loadMessages(): Promise<ListResult<MessageBalloonDto>> {
        const messages = await this.root.userRpc.userMessage.getAll(this.skip, this.take, "");
        const reordered = messages.items.sort((left, right) => {
            return new Date(left.createdAt).getTime() - new Date(right.createdAt).getTime();
        });
        return {
            items: reordered,
            totalCount: messages.totalCount,
        };
    }

    async sendMessage(): Promise<void> {
        await this.root.userRpc.userMessage.send(this.message);
    }
}
