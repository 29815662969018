import { action, observable } from "mobx";
import {
    CustomConstraint,
    InnValueConstraint,
    KppValueConstraint,
    OgrnValueConstraint,
    reduceValidationErrorsToErrors,
} from "src/utilities";
import { IsNotEmpty, validate } from "@keroosha/class-validator";
import { Organization, OrganizationInfo } from "src/api";

type ConfigureOrganizationErrors = {
    inn?: string[];
    ogrn?: string[];
    kpp?: string[];
    address?: string[];
    bankInfo?: string[];
    title?: string[];
};

export abstract class ConfigureOrganizationStore {
    @observable isBusy: boolean = false;
    @observable id: string = "";
    @observable errors: ConfigureOrganizationErrors = {};

    @CustomConstraint(InnValueConstraint)
    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    inn: string = "";

    @CustomConstraint(OgrnValueConstraint)
    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    ogrn: string = "";

    @CustomConstraint(KppValueConstraint)
    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    kpp: string = "";

    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    address: string = "";

    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    bankInfo: string = "";

    @IsNotEmpty({ message: "Обязательно для заполнения." })
    @observable
    title: string = "";

    protected abstract update(organization: { id: string, title: string; info: OrganizationInfo }): Promise<void>;

    @action async save(): Promise<void> {
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors(errors);
            return;
        }

        this.errors = {};
        this.isBusy = true;
        await this.update({
            id: this.id,
            title: this.title,
            info: {
                inn: this.inn,
                kpp: this.kpp,
                ogrn: this.ogrn,
                address: this.address,
                bankInfo: this.bankInfo,
            },
        });
        this.isBusy = false;
    }

    @action setFields(organization: Organization) {
        this.id = organization.id;
        this.title = organization.title;
        this.inn = organization.info.inn;
        this.ogrn = organization.info.ogrn;
        this.address = organization.info.address;
        this.bankInfo = organization.info.bankInfo;
        this.kpp = organization.info.kpp;
    }

    @action resetFields() {
        this.inn = "";
        this.kpp = "";
        this.ogrn = "";
        this.address = "";
        this.bankInfo = "";
        this.title = "";
        this.id = "";
    }
}
