import React, { FC, Suspense } from "react";
import { Fade } from "reactstrap";
import { Spinner } from "src/components/Spinner/Spinner";
import styles from "src/components/SuspensePlaceholder/suspensePlaceholder.module.css";

const Fallback = () => {
    return (
        <Fade className={styles.container}>
            <Spinner className={styles.loading} size="large" variant="important" />
            <div className={styles.description}>Пожалуйста, подождите...</div>
        </Fade>
    );
};

export const SuspensePlaceholder: FC = ({ children }) => <Suspense fallback={Fallback()}>{children}</Suspense>;
