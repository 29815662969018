import { RootStore } from "src/stores/RootStore";
import { action, computed, observable, runInAction } from "mobx";
import { IsNotEmpty } from "@keroosha/class-validator";

export class UpdateProfileAvatarStore {
    @observable uploadMode = false;

    @computed get userStore() {
        return this.root.userProfileStore;
    }

    constructor(private readonly root: RootStore) {}
}

type UpdateProfileStoreErrors = {
    firstName?: string[];
    lastName?: string[];
};

export class UserUpdateProfileStore {
    @observable error?: string;
    @observable errors: UpdateProfileStoreErrors;
    @observable userAvatarStore;

    @IsNotEmpty({ message: "Обязательно для заполнения" }) @observable firstName;
    @IsNotEmpty({ message: "Обязательно для заполнения" }) @observable lastName;
    @observable email;

    constructor(private readonly root: RootStore) {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.errors = {};
        this.userAvatarStore = new UpdateProfileAvatarStore(root);
    }

    @computed get loading() {
        return false;
    }

    @computed get initialized() {
        return true;
    }

    @action async loadAll() {
        runInAction(() => {
            if (this.root.userProfileStore.profile) {
                const { name, email } = this.root.userProfileStore.profile;
                const [firstName = "", lastName = ""] = name.split(" ");
                this.firstName = firstName;
                this.lastName = lastName;
                this.email = email;
            }
        });
    }
}
