import React from "react";
import {
    AdminOrganizationEditPage,
    AdminOrganizationListPage,
    AdminUserEditPage,
    AdminUserListPage
} from "src/pages/admin/AdminLazyRoutes";
import { Route, RouterState } from "mobx-state-router";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { AnonRouteNames, UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook } from "src/pages/user/UserRoutes";

export enum AdminRouteNames {
    userList = "admin-user-list",
    userEdit = "admin-user-edit",
    organizationList = "admin-organization-list",
    organizationEdit = "admin-organization-edit",
}

export const AdminViewMap = {
    [AdminRouteNames.organizationList]: <AdminOrganizationListPage />,
    [AdminRouteNames.organizationEdit]: <AdminOrganizationEditPage />,
    [AdminRouteNames.userList]: <AdminUserListPage />,
    [AdminRouteNames.userEdit]: <AdminUserEditPage />,
};

export const AdminRoutes: Route[] = convertRoutes([
    {
        pattern: "/admin/user",
        name: AdminRouteNames.userList,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook],
        onEnter: async (root) => root.adminUserStore.load(),
    },
    {
        pattern: "/admin/user/:userId",
        name: AdminRouteNames.userEdit,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook],
        onEnter: async (root, to) => root.adminUserStore.loadWithEditor(to.params["userId"]),
    },
    {
        pattern: "/admin/organization",
        name: AdminRouteNames.organizationList,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook],
        onEnter: async (root) => root.adminOrganizationStore.load(),
    },
    {
        pattern: "/admin/organization/:organizationId",
        name: AdminRouteNames.organizationEdit,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook],
        onEnter: async (root, to) => root.adminOrganizationStore.loadWithEditor(to.params["organizationId"]),
    },
]);
