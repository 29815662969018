import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import { ListResult, RemoteUiDefinitionDto, Result, AdminUserDto, AdminUserCreateArgsDto } from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminUserStoreArgs = {};

export class AdminUserStore extends RemoteUiCrudEditorStore<AdminUserDto, AdminUserCreateArgsDto, AdminUserStoreArgs> {
    constructor(root: RootStore) {
        super(root, AdminRouteNames.userList, AdminRouteNames.userList);
    }

    protected createNew(args: AdminUserCreateArgsDto): Promise<Result> {
        throw new Error("Create user is not implemented.");
    }

    protected getAll(): Promise<ListResult<AdminUserDto>> {
        return this.root.userRpc.adminUser.getAll({
            skip: this.skip,
            take: this.take,
            search: {
                property: "",
                keyword: "",
            }
        });
    }

    protected async getById(id: string): Promise<AdminUserDto | undefined> {
        const response = await this.root.userRpc.adminUser.getById(id);
        return response.success ? response.value : undefined;
    }

    protected update(args: AdminUserCreateArgsDto): Promise<Result> {
        return this.root.userRpc.adminUser.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("User delete is not implemented.");
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<AdminUserCreateArgsDto>> {
        return await this.root.userRpc.adminUser.getRemoteUi();
    }
}
