import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    Result,
    UPanelAccountDto,
    UPanelEconomicEntityContractCreateArgsDto,
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityDto,
    UPanelEconomicEntityTariffDto,
    UPanelSubAccountDto,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { IsNotEmpty, validate } from "@keroosha/class-validator";
import { action, observable } from "mobx";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";
import { AutoCompleteBoxNotEmpty, reduceValidationErrorsToErrors } from "src/utilities";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

type ProjectContractTariffRouteArgs = {
    oid: string;
    pid: string;
};

type ProjectContractTariffStoreErrors = {
    tariffs: string[];
};

export class ProjectContractTariffStore extends ReactTableStore<
    UPanelEconomicEntityTariffDto,
    ProjectContractTariffRouteArgs
> {
    @observable
    errors?: ProjectContractTariffStoreErrors;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    tariffs: ConfigurableAutoCompleteStore<UPanelEconomicEntityTariffDto>;

    constructor(private readonly root: RootStore, readonly contracts: ProjectContractStore) {
        super();
        this.tariffs = new ConfigurableAutoCompleteStore<UPanelEconomicEntityTariffDto>({
            suggest: () => this.root.userRpc.projectTariff.getAll(this.args.oid, this.args.pid, 0, 30),
        });
    }

    @action
    public async attachTariff(): Promise<void> {
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors<ProjectContractTariffStoreErrors>(errors);
            return;
        }

        this.errors = undefined;
        const cid = this.contracts.selectedItemId;
        const tid = this.tariffs.value?.id;
        if (!cid || !tid) return;
        const response = await this.root.userRpc.projectContract.attachTariff(this.args.oid, this.args.pid, cid, tid);
        if (!response.success) alert(response.error.description + " " + response.error.code);
        await this.refresh();
    }

    @action
    public async refresh(): Promise<void> {
        const contractId = this.contracts.selectedItemId;
        const rpc = this.root.userRpc.projectContract;
        const tariffs = await rpc.getContractTariffs(this.args.oid, this.args.pid, contractId, this.skip, this.take);
        this.fillItems(tariffs.items, tariffs.totalCount);
    }
}

type ProjectContractRouteArgs = {
    economicEntityId: string;
    oid: string;
    pid: string;
};

type ProjectContractStoreErrors = {
    name: string[];
    issuerAccounts: string[];
    issuerSubAccounts: string[];
    partners: string[];
    partnerAccounts: string[];
    partnerSubAccounts: string[];
};

export class ProjectContractStore extends CrudEditorStore<
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityContractCreateArgsDto,
    ProjectContractStoreErrors,
    ProjectContractRouteArgs
> {
    @IsNotEmpty({ message: "Поле не должно быть пустым." })
    @observable
    name: string = "";
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    issuerAccounts: ConfigurableAutoCompleteStore<UPanelAccountDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    issuerSubAccounts: ConfigurableAutoCompleteStore<UPanelSubAccountDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    partnerAccounts: ConfigurableAutoCompleteStore<UPanelAccountDto>;
    @AutoCompleteBoxNotEmpty({ message: "Поле не может быть пустым." })
    @observable
    partnerSubAccounts: ConfigurableAutoCompleteStore<UPanelSubAccountDto>;

    @observable issuer?: UPanelEconomicEntityDto;
    @observable partner?: UPanelEconomicEntityDto;
    @observable tariffs: ProjectContractTariffStore;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.contractList, ProjectRouteNames.contractAdd);
        const u = this.root.userRpc;
        this.issuerAccounts = new ConfigurableAutoCompleteStore<UPanelAccountDto>({
            suggest: () => u.projectAccount.getAll(this.args.oid, this.args.pid, this.issuer!.id, 0, 30),
            onSelectionChange: async () => {
                this.issuerSubAccounts.value = undefined;
                await this.issuerSubAccounts.clearSuggestions();
            },
        });
        this.issuerSubAccounts = new ConfigurableAutoCompleteStore<UPanelSubAccountDto>({
            suggest: () =>
                u.projectSubAccount.getAll(this.args.oid, this.args.pid, this.issuerAccounts.value!.id, 0, 30),
        });
        this.partnerAccounts = new ConfigurableAutoCompleteStore<UPanelAccountDto>({
            suggest: () => u.projectAccount.getAll(this.args.oid, this.args.pid, this.partner!.id, 0, 30),
            onSelectionChange: async () => {
                this.partnerSubAccounts.value = undefined;
                await this.partnerSubAccounts.clearSuggestions();
            },
        });
        this.partnerSubAccounts = new ConfigurableAutoCompleteStore<UPanelSubAccountDto>({
            suggest: () =>
                u.projectSubAccount.getAll(this.args.oid, this.args.pid, this.partnerAccounts.value!.id, 0, 30),
        });
        this.tariffs = new ProjectContractTariffStore(this.root, this);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const rpc = this.root.userRpc.projectEconomicEntity;
        const issuer = await rpc.getRootEconomicEntity(this.args.oid, this.args.pid);
        const partner = await rpc.getById(this.args.oid, this.args.pid, this.args.economicEntityId);
        this.issuer = issuer.value;
        this.partner = partner.value;
        if (this.selectedItemId != this.dummySelectedItemId) {
            await this.tariffs.load({ oid: this.args.oid, pid: this.args.pid });
        }
    }

    protected createNew(args: UPanelEconomicEntityContractCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectContract.create(this.args.oid, this.args.pid, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.projectContract.delete(this.args.oid, this.args.pid, id);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityContractDto>> {
        const rpc = this.root.userRpc.projectContract;
        return rpc.getAll(this.args.oid, this.args.pid, this.args.economicEntityId, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityContractDto | undefined> {
        const response = await this.root.userRpc.projectContract.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelEconomicEntityContractCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectContract.update(this.args.oid, this.args.pid, this.selectedItemId, args);
    }

    protected getFields(): UPanelEconomicEntityContractCreateArgsDto {
        return {
            name: this.name,
            partnerId: this.args.economicEntityId,
            partnerSubAccountId: this.partnerSubAccounts.value?.id!,
            issuerSubAccountId: this.issuerSubAccounts.value?.id!,
        };
    }

    protected resetFields(): void {
        this.name = "";
        this.issuerAccounts.value = undefined;
        this.issuerSubAccounts.value = undefined;
        this.partnerAccounts.value = undefined;
        this.partnerSubAccounts.value = undefined;
    }

    protected async setFields(item: UPanelEconomicEntityContractDto): Promise<void> {
        const u = this.root.userRpc;
        const issuerSub = await u.projectSubAccount.getById(this.args.oid, this.args.pid, item.issuerSubAccountId);
        const issuerAccount = await u.projectAccount.getById(this.args.oid, this.args.pid, issuerSub.value.accountId);
        const partnerSub = await u.projectSubAccount.getById(this.args.oid, this.args.pid, item.partnerSubAccountId);
        const partnerAccount = await u.projectAccount.getById(this.args.oid, this.args.pid, partnerSub.value.accountId);
        this.partnerAccounts.value = partnerAccount.value;
        this.partnerSubAccounts.value = partnerSub.value;
        this.issuerAccounts.value = issuerAccount.value;
        this.issuerSubAccounts.value = issuerSub.value;
        this.name = item.name;
    }
}
